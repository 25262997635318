import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import { KatalMonitoringDriver, KatalMonitoringDriverOptions } from '@amzn/katal-monitoring-aws-driver';
import { APP_NAME_SHORT } from '../constants/generic-constants';
import { MetricDimensionNames } from '../constants/metric-constants';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const buildMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === 'dev') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  } else {
    const monitoringConfig: KatalMonitoringDriverOptions = {
      url: 'https://monitoring.xpt.device.finance.amazon.dev/v1/monitoring'
    };
    return new KatalMonitoringDriver(monitoringConfig);
  }
};

const buildMetricsPublisher = (pageName: string, userAlias?: string): KatalMetrics.Publisher => {
  const metricsDriver = buildMetricsDriver();
  let initialMetricsContext = new KatalMetrics.Context.Builder().withSite(APP_NAME_SHORT).withServiceName(APP_NAME_SHORT);
  initialMetricsContext = userAlias
    ? initialMetricsContext
        .withCloudWatchDimensions([
          new KatalMetrics.Metric.String(MetricDimensionNames.PAGE_NAME_DIMENSION, pageName),
          new KatalMetrics.Metric.String(MetricDimensionNames.USER_ALIAS_DIMENSION, userAlias)
        ])
        .build()
    : initialMetricsContext.withCloudWatchDimensions([new KatalMetrics.Metric.String(MetricDimensionNames.PAGE_NAME_DIMENSION, pageName)]).build();
  return new KatalMetrics.Publisher(metricsDriver, metricsConsoleErrorHandler, initialMetricsContext);
};

export const userMetricsPublisher = (pageName: string, userAlias?: string) => buildMetricsPublisher(pageName, userAlias);
export const genericMetricsPublisher = (pageName: string) => buildMetricsPublisher(pageName);

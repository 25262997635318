// Environment variables object
const ENVIRONMENT_VARIABLES = Object.freeze({
  env: {
    Region: 'us-west-2',
    Stage: 'prod',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd1yetweym1swiz.cloudfront.net',
    CognitoUserPoolId: 'us-west-2_ppk4EjpIw',
    CognitoIdentityPoolId: 'us-west-2:e80eff26-3f8b-45fa-91f5-1b2d1e3a9703',
    AuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
    CognitoUserPoolWebClientId: '5pbn3u3plqe13b3kio420nl4m7',
    CognitoDomain: 'xpt-application-prod.auth.us-west-2.amazoncognito.com',
    ApplicationDomain: 'https://xpt.device.finance.amazon.dev',
    AwsAccountId: '650538251700'
  }
});

export default ENVIRONMENT_VARIABLES;
